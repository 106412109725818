import { Card, Col, Empty, Radio, Row, Space } from "antd";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import "./MyBids.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyBidsAPI } from "../../apis/bids/BidAPI";
import { _setMyBidsAction } from "../../redux/actions/bids/BidsActions";
import {
  formatDate,
  getTimeAMPMFromDate,
  renderAuctionItemBGColorOnStatus,
  renderAuctionStatusBarColor,
  renderAuctionStatusColor,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import { useNavigate } from "react-router-dom";
import { constRoute } from "../../utils/Constants";
import Loader from "../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import Link from "antd/es/typography/Link";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import { useMediaQuery } from "@uidotdev/usehooks";
import filterIcon from "../../assets/filter-icon.svg";
import { getAllManufacturerAPI } from "../../apis/manufacturer/manufacturer";
import { getAllGradesAPI } from "../../apis/grades";
import ClockIcon from "../../assets/clock-icon.svg";
import TimeRemainingTimer from "../../components/CommonComponents/RemainingTimeTimer";
import HighestBidderIcon from "../../assets/highest-bidder-icon.svg";
import socket from "../../socket";
import InfoBid from "../../components/InfoBid/Index";

const MyBids = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const myBids = useSelector((state) => state?.bids?.myBids);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gradeFilter, setGradeFilter] = useState("");
  const [endingFilter, setEndingFilter] = useState("");
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [manufacturerFilter, setManufacturerFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [gradesList, setGradesList] = useState([]);
  const [typeFilter, setTypeFilter] = useState("");

  const getManufacturerApi = async () => {
    setLoading(true);
    try {
      const response = await getAllManufacturerAPI();
      if (response?.data?.statusCode === 200) {
        setManufacturerList(response?.data?.data);
        const allChildrenArray = response?.data?.data.reduce((acc, obj) => {
          return acc.concat(obj.models);
        }, []);
        setModelsList(allChildrenArray);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const getGradesApi = async () => {
    setLoading(true);
    try {
      const response = await getAllGradesAPI();
      if (response?.data?.statusCode === 200) {
        setGradesList(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getMyBidsAPI(
        gradeFilter,
        endingFilter,
        manufacturerFilter,
        modelFilter,
        typeFilter,
        statusFilter == "all" ? "" : statusFilter
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        dispatch(_setMyBidsAction(result.data.data));
        setLoading(false);
      } else throw result.data.data;
    } catch (e) {
      setLoading(false);
      requestErrorHandel(e, navigate);
    }
  }, [
    dispatch,
    gradeFilter,
    endingFilter,
    manufacturerFilter,
    modelFilter,
    typeFilter,
    statusFilter,
  ]);

  const handleSocketEvent = useCallback(async () => {
    try {
      await fetchData();
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    socket.on("auction", (data) => {
      handleSocketEvent(data);
    });
    return () => {
      socket.off("auction", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getManufacturerApi();
    getGradesApi();
    localStorage.setItem("selectedAddress", 0);
  }, []);

  const onChange = (groupName) => (e) => {
    const value = e.target.value;
    switch (groupName) {
      case "status":
        setStatusFilter(value);
        break;
      case "manufacturer":
        setManufacturerFilter(value);
        break;
      case "model":
        setModelFilter(value);
        break;
      case "grade":
        setGradeFilter(value);
        break;
      case "type":
        setTypeFilter(value);
        setEndingFilter("");
        break;
      case "isSoon":
        setEndingFilter(value);
        setTypeFilter("");
        break;
      default:
    }
  };

  const resetFilters = () => {
    setEndingFilter("");
    setGradeFilter("");
    setManufacturerFilter("");
    setModelFilter("");
    getManufacturerApi();
    setTypeFilter("");
    setStatusFilter("");
  };

  const onOpenBottomSheet = () => setOpenFilterSheet(true);

  const renderBidDetails = (bids) => {
    return (
      <Row>
        <Col md={24} sm={24} xs={24}>
          <div className="auction-head-container">
            <div>
              <h1>{renderItemDataOrEmptyNull(bids?.name)}</h1>
              <p className="t-slate">
                {t(`auctionDetails.auctionID`)}: {bids?.identifier}
              </p>
            </div>
            <div>
              <p
                style={{
                  background: renderAuctionStatusColor(
                    bids?.bidStatuses?.identifier
                  ),
                  border: `1px solid ${renderAuctionStatusBarColor(
                    bids?.bidStatuses?.identifier
                  )}`,
                }}
                className={`bidStatusContainer ${i18n.language} my-bids-cell-won`}
              >
                {renderItemDataOrEmptyNull(bids?.bidStatuses?.status)}
              </p>
            </div>
          </div>
        </Col>
        <Col md={24} sm={24} xs={24}>
          <Row className="firstRowContainer">
            <Col md={8} sm={12} xs={12}>
              <Row gutter={20} className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.serialNumber`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(bids.serialNumber)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`manufacturer`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(bids.manufacture?.name)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row gutter={20} className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">
                    {t(`auctionDetails.Sku`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span
                    className="semiBold__font"
                    style={{ wordBreak: "break-word" }}
                  >
                    {renderItemDataOrEmptyNull(bids.sku)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="textRight medium__font t-slate">
                    {t(`auctionDetails.grade`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(bids.grade)}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="medium__font t-slate">{t(`quantity`)}</span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(bids.totalUnits)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Row className={"auctionPropertiesRow"}>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="textRight medium__font t-slate">
                    {t(`auctionDetails.noOfBids`)}
                  </span>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                  <span className="semiBold__font">
                    {renderItemDataOrEmptyNull(bids.totalBids)}
                  </span>
                </Col>
              </Row>
            </Col>
            {bids?.bidStatuses?.status === "Open" && (
              <Col md={8} sm={12} xs={12}>
                <Row className={"auctionPropertiesRow"}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <span className="textRight medium__font t-slate">
                      {t(`auctionDetails.lastBid`)}
                    </span>
                  </Col>

                  <Col lg={12} md={24} sm={24} xs={24}>
                    <span className="semiBold__font">
                      $
                      {renderItemDataOrEmptyNull(
                        bids.lastBidPrice / bids.totalUnits,
                        2
                      )}
                      /Per Unit
                    </span>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>

        <Col span={24}>
          {bids?.bidStatuses?.identifier === "B-0001" && (
            <Row className="item" style={{ marginTop: "5px" }}>
              <img
                src={ClockIcon}
                alt={"time"}
                className={"auction-clock-icon-my-bids"}
              />
              <p className="semiBold__font">
                <TimeRemainingTimer endAt={bids?.endAt} />
              </p>
            </Row>
          )}
        </Col>
      </Row>
    );
  };

  const openDetails = (data) => {
    // if (data?.bidStatuses?.identifier === "B-0001") {
    navigate(`/auction-detail/${data?.id}`);
    // }
  };

  const renderBidItem = (bids) => {
    return (
      <div>
        <Row gutter={[10, 10]} align={"top"} justify={"space-between"}>
          <Col md={24} sm={24} xs={24}>
            {renderBidDetails(bids)}
            {/* 
              "" - No bid placed
              B-0001 - highest bidder
              B-0002 - won and pending payment
              B-0003 - won and payment done
            */}
            {["B-0001"].includes(bids?.bidStatuses?.identifier) && (
              <>
                <div className="disclaimer-container">
                  <InfoBid />
                </div>
              </>
            )}
          </Col>
        </Row>

        {(bids?.bidStatuses?.identifier === "B-0003" && (
          <Row justify={"end"} className="my-bid-footer-button" gutter={[20]}>
            <Link href={bids?.shipmentLink} target="_blank">
              <CustomButton
                className={"shipmentBtn"}
                disabled={bids?.shipmentLink === undefined}
                title={
                  bids?.isShipmentAdded
                    ? t(`auctionDetails.track_Shipment`)
                    : t(`auctionDetails.pending_shipment`)
                }
              />
            </Link>
          </Row>
        )) ||
          (bids?.bidStatuses?.identifier === "B-0002" && (
            <Row className="my-bid-footer-button" gutter={[20]}>
              <Col
                style={{
                  background: renderAuctionItemBGColorOnStatus(
                    bids?.bidStatuses?.identifier
                  ),
                }}
                md={16}
                // className="my-bid-payment-date"
              >
                <p className="medium__font">
                  Pay Before: {formatDate(bids?.bidStatuses?.payBefore)} |{" "}
                  {getTimeAMPMFromDate(bids?.bidStatuses?.payBefore)}
                </p>
              </Col>
              <Col md={8}>
                <Row justify={"end"} gutter={[20]}>
                  <CustomButton
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(constRoute?.checkout, { state: bids });
                    }}
                    title={t("proceedToPay")}
                  />
                </Row>
              </Col>
            </Row>
          ))}
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <Card>
        <Row justify={"space-between"}>
          <p className="semiBold__font">{t(`auctions.viewBy`)}</p>
          <p className="t-red semiBold__font t-cursor" onClick={resetFilters}>
            {t(`auctions.reset`)}
          </p>
        </Row>
        <p
          className="t-slate-light medium__font"
          style={{ marginTop: 20, marginBottom: 6 }}
        >
          {t(`status`)}
        </p>
        <Radio.Group onChange={onChange("status")} value={statusFilter}>
          <Space direction="vertical">
            <Radio value={"all"}>{t(`filter.all`)}</Radio>
            <Radio value={"won"}>{t(`filter.won`)}</Radio>
            <Radio value={"lost"}>{t(`filter.lost`)}</Radio>
            <Radio value={"open"}>{t(`filter.open`)}</Radio>
          </Space>
        </Radio.Group>
        <div className="filters-list">
          {/* Auction Ending */}
          <p
            className="t-slate-light medium__font"
            style={{ marginTop: 20, marginBottom: 6 }}
          >
            {t(`filter.auctionEnding`)}
          </p>
          <Radio.Group onChange={onChange("isSoon")} value={endingFilter}>
            <Space direction="vertical">
              <Radio value={"1"}>{t(`filter.soon`)}</Radio>
            </Space>
          </Radio.Group>

          <p
            className="t-slate-light medium__font"
            style={{ marginTop: 20, marginBottom: 6 }}
          >
            {t(`others`)}
          </p>
          <Radio.Group onChange={onChange("type")} value={typeFilter}>
            <Space direction="vertical">
              <Radio value={"lowPrice"}>{t(`filter.priceLowToHigh`)}</Radio>
              <Radio value={"highPrice"}>{t(`filter.priceHighToLow`)}</Radio>
              <Radio value={"lowBids"}>{t(`filter.lowBids`)}</Radio>
              <Radio value={"highBids"}>{t(`filter.highBids`)}</Radio>
              <Radio value={"latest"}>{t(`filter.latest`)}</Radio>
              <Radio value={"oldest"}>{t(`filter.oldest`)}</Radio>
            </Space>
          </Radio.Group>

          {/* manufacturer */}
          <p
            className="t-slate-light medium__font"
            style={{ marginTop: 20, marginBottom: 6 }}
          >
            {t(`manufacturer`)}
          </p>
          <Radio.Group
            onChange={onChange("manufacturer")}
            value={manufacturerFilter}
          >
            {manufacturerList?.map((item) => {
              return (
                <div>
                  <Radio
                    onClick={() => {
                      setModelsList(item?.models);
                    }}
                    value={item?.id}
                  >
                    {renderItemDataOrEmptyNull(item?.name)}
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
          <p
            className="t-slate-light medium__font"
            style={{ marginTop: 20, marginBottom: 6 }}
          >
            {t(`filter.grade`)}
          </p>
          <Radio.Group onChange={onChange("grade")} value={gradeFilter}>
            {gradesList?.map((item) => {
              return (
                <div>
                  <Radio value={item?.id}>{item?.title}</Radio>
                </div>
              );
            })}
          </Radio.Group>
        </div>
      </Card>
    );
  };

  return (
    <div className="common-layout my-bids-main">
      <ProtectedHeader />
      <Loader loading={loading} />
      <section style={{ marginTop: 20 }}>
        <Row justify={"space-between"}>
          <h2 className="bold__font">{t(`myBids.myBids`)}</h2>

          {isSmallDevice && (
            <button onClick={onOpenBottomSheet}>
              <img src={filterIcon} alt={"filter"} />
            </button>
          )}
        </Row>
        <Row className="auction-row" gutter={[20]}>
          {!isSmallDevice && (
            <Col md={6} className="filter-section">
              {renderFilters()}
            </Col>
          )}
          <Col md={18}>
            {myBids?.length > 0 ? (
              myBids.map((bids) => {
                return (
                  <>
                    {bids?.bidStatuses?.barStatus ? (
                      <div
                        className="highestBidder"
                        style={{
                          background: renderAuctionItemBGColorOnStatus(
                            bids?.bidStatuses?.identifier
                          ),
                        }}
                      >
                        <img src={HighestBidderIcon} alt="Highest" />
                        <p> {bids?.bidStatuses?.barStatus}</p>
                      </div>
                    ) : null}

                    <Card
                      // hoverable={bids?.bidStatuses?.identifier === "B-0001"}
                      hoverable={true}
                      bordered
                      style={{
                        marginTop: 0,
                        borderRadius: "0px 0px 10px 10px",
                        background: renderAuctionItemBGColorOnStatus(
                          bids?.bidStatuses?.identifier
                        ),
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        openDetails(bids);
                      }}
                    >
                      {renderBidItem(bids)}
                    </Card>
                  </>
                );
              })
            ) : (
              <Empty
                description={<p className="medium__font">{t("noDataFound")}</p>}
              />
            )}
          </Col>
        </Row>
        <BottomSheet open={openFilterSheet}>{renderFilters()}</BottomSheet>
      </section>
    </div>
  );
};

export default MyBids;
