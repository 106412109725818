import { useMediaQuery } from "@uidotdev/usehooks";
import { Card, Col, Empty, Radio, Row, Space, Spin } from "antd";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { getAllAuctionsAPI } from "../../apis/auctions/AuctionsAPI";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import filterIcon from "../../assets/filter-icon.svg";
import backIcon from "../../assets/back-arrow-icon.svg";
import "./Auctions.css";
import { useLocation, useNavigate } from "react-router-dom";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import { useTranslation } from "react-i18next";
import {
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import { getAllManufacturerAPI } from "../../apis/manufacturer/manufacturer";
import { getAllGradesAPI } from "../../apis/grades";
import socket from "../../socket";
import CommonInfiniteSkroll from "../../components/CommonComponents/common-infinite-skroll";
import { INITIAL_PAGE_LIMIT, INITIAL_PAGE_NUMBER } from "../../utils/const";
import moment from "moment";
import { m } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { init } from "i18next";

const Auctions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const category = location.state?.category;
  const search = location.state?.search;
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const [auctionListData, setAuctionListData] = useState([]);
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
  const [limit] = useState(INITIAL_PAGE_LIMIT);
  const [resTotalPages, setResTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [gradeFilter, setGradeFilter] = useState("");
  const [endingFilter, setEndingFilter] = useState("");
  const [manufacturerFilter, setManufacturerFilter] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [gradesList, setGradesList] = useState([]);

  const [totalRecord, setTotalRecord] = useState(0);
  const getManufacturerApi = async (forceCall = false) => {
    // setLoading(true);

    if (manufacturerList.length > 0 && !forceCall) return;
    try {
      const response = await getAllManufacturerAPI();
      if (response?.data?.statusCode === 200) {
        setManufacturerList(response?.data?.data);
        const allChildrenArray = response?.data?.data.reduce((acc, obj) => {
          return acc.concat(obj.models);
        }, []);
        setModelsList(allChildrenArray);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      // setLoading(false);
    }
  };

  // console.log("acutions list", auctionListData);

  const getGradesApi = async () => {
    if (gradesList.length > 0) return;

    // setLoading(true);
    try {
      const response = await getAllGradesAPI(category?.id);
      if (response?.data?.statusCode === 200) {
        setGradesList(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      // setLoading(false);
    }
  };

  const fetchAuctions = useCallback(async () => {
    try {
      if (pageNumber === 1) {
        setLoading(true);
      }
      const result = await getAllAuctionsAPI(
        gradeFilter,
        endingFilter,
        category?.id,
        search,
        manufacturerFilter,
        modelFilter,
        typeFilter,
        pageNumber,
        limit
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        if (pageNumber === 1) {
          setAuctionListData(result.data.data);
        } else {
          setAuctionListData([...auctionListData, ...result.data.data]);
        }
        setTotalRecord(result?.data?.total);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log("error in fetch auctions...", e);
    }
  }, [
    gradeFilter,
    endingFilter,
    category,
    search,
    manufacturerFilter,
    modelFilter,
    typeFilter,
    pageNumber,
  ]);

  useEffect(() => {
    fetchAuctions();
    if (manufacturerList.length === 0) getManufacturerApi();
    if (gradesList.length === 0) getGradesApi();
  }, [
    gradeFilter,
    endingFilter,
    category,
    search,
    manufacturerFilter,
    modelFilter,
    typeFilter,
    pageNumber,
  ]);

  const handleSocketEvent = useCallback(async () => {
    try {
      await fetchAuctions();
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    socket.on("auction", (data) => {
      handleSocketEvent(data);
    });
    return () => {
      socket.off("auction", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  const onChange = (groupName) => (e) => {
    setPageNumber(1);
    const value = e.target.value;
    switch (groupName) {
      case "manufacturer":
        setManufacturerFilter(value);
        break;
      case "model":
        setModelFilter(value);
        break;
      case "type":
        setTypeFilter(value);
        setEndingFilter("");
        break;
      case "grade":
        setGradeFilter(value);
        break;
      case "isSoon":
        setEndingFilter(value);
        setTypeFilter("");
        break;
      default:
    }
  };

  const goBack = () => navigate(-1);

  const onOpenBottomSheet = () => setOpenFilterSheet(true);

  const resetFilters = () => {
    setEndingFilter("");
    setGradeFilter("");
    setManufacturerFilter("");
    setModelFilter("");
    getManufacturerApi(true);
    setTypeFilter("");
  };

  const renderFilters = () => {
    return (
      <Card>
        <Row justify={"space-between"}>
          <p className="semiBold__font">{t(`auctions.viewBy`)}</p>
          <p className="t-red semiBold__font t-cursor" onClick={resetFilters}>
            {t(`auctions.reset`)}
          </p>
        </Row>

        <div className="filters-list">
          {/* Auction Ending */}
          <p className="t-slate-light medium__font">
            {t(`filter.auctionEnding`)}
          </p>

          <Radio.Group onChange={onChange("isSoon")} value={endingFilter}>
            <Space direction="vertical">
              <Radio value={"1"}>{t(`filter.soon`)}</Radio>
            </Space>
          </Radio.Group>

          <p className="t-slate-light medium__font">{t(`others`)}</p>

          <Radio.Group onChange={onChange("type")} value={typeFilter}>
            <Space direction="vertical">
              <Radio value={"lowPrice"}>{t(`filter.priceLowToHigh`)}</Radio>
              <Radio value={"highPrice"}>{t(`filter.priceHighToLow`)}</Radio>
              <Radio value={"lowBids"}>{t(`filter.lowBids`)}</Radio>
              <Radio value={"highBids"}>{t(`filter.highBids`)}</Radio>
              <Radio value={"latest"}>{t(`filter.latest`)}</Radio>
              <Radio value={"oldest"}>{t(`filter.oldest`)}</Radio>
            </Space>
          </Radio.Group>

          {/* manufacturer */}

          <p className="t-slate-light medium__font">{t(`manufacturer`)}</p>
          <Radio.Group
            onChange={onChange("manufacturer")}
            value={manufacturerFilter}
          >
            {manufacturerList?.map((item, index) => {
              return (
                <div key={index}>
                  <Radio
                    onClick={() => {
                      setModelsList(item?.models);
                    }}
                    value={item?.id}
                  >
                    {renderItemDataOrEmptyNull(item?.name)}
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>

          {/* models */}
          {/* <p className="t-slate-light medium__font">{t(`models`)}</p>
          <Radio.Group onChange={onChange("model")} value={modelFilter}>
            {modelsList?.map((modelItem, index) => {
              return (
                <div key={index}>
                  <Radio value={modelItem?.id}>{modelItem?.name}</Radio>
                </div>
              );
            })}
          </Radio.Group> */}

          {/* Grade */}
          <p className="t-slate-light medium__font">{t(`filter.grade`)}</p>
          <Radio.Group onChange={onChange("grade")} value={gradeFilter}>
            {gradesList?.map((item, index) => {
              return (
                <div key={index}>
                  <Radio value={item?.id}>{item?.title}</Radio>
                </div>
              );
            })}
          </Radio.Group>
        </div>
      </Card>
    );
  };

  const handleAuctionClosed = (id) => {
    const _data = [...auctionListData];
    const updatedAuctionList = _data.filter((auction) => {
      const currentTime = moment().tz("America/Chicago");
      const endsAtTime = moment(auction.endAt);
      const timeDifference = endsAtTime.diff(currentTime, "milliseconds");

      return timeDifference > 0; // Filter items with positive time difference
    });

    // Update the state with the filtered list of auctions
    setAuctionListData(updatedAuctionList);
  };

  return (
    <div className="common-layout all-auction-main">
      <ProtectedHeader />
      <section>
        <Row
          justify={"space-between"}
          align={"middle"}
          className={"auction-header-row"}
        >
          {isSmallDevice && (
            <button onClick={goBack}>
              <img src={backIcon} alt={"back"} />
            </button>
          )}
          <h2 className="bold__font">
            {" "}
            {search
              ? `${t(`searchedBy`)}: ${search}`
              : category?.title
              ? `${t("carrier")}: ${category?.title}`
              : t(`auctions.auctions`)}
          </h2>
          {isSmallDevice && (
            <button onClick={onOpenBottomSheet}>
              <img src={filterIcon} alt={"filter"} />
            </button>
          )}
        </Row>
        <Row className="auction-row" gutter={[20]}>
          {!isSmallDevice && (
            <Col md={6} className="filter-section">
              {renderFilters()}
            </Col>
          )}
          <Col md={18} xs={24}>
            {loading ? (
              <div className="center-aligned-spinner">
                {" "}
                <Spin spinning={loading} className="loading-spinner" />
              </div>
            ) : auctionListData?.length === 0 ||
              auctionListData === undefined ? (
              <Empty
                description={<p className="medium__font">{t("noDataFound")}</p>}
              />
            ) : (
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={auctionListData?.length}
                hasMore={auctionListData.length < totalRecord}
                loader={
                  auctionListData.length === totalRecord ? null : (
                    <div className="load-more">
                      <Spin spinning={true} className="loading-spinner" />
                    </div>
                  )
                }
                next={() => setPageNumber(pageNumber + 1)}
                id="auctionList"
              >
                <HomeAuctionList
                  data={auctionListData}
                  onAuctionClosed={handleAuctionClosed}
                  type={"auctions-page"}
                  loading={loading}
                />
              </InfiniteScroll>
            )}
          </Col>
        </Row>

        <BottomSheet open={openFilterSheet}>{renderFilters()}</BottomSheet>
      </section>
    </div>
  );
};

export default Auctions;
