import { Checkbox, Col, Empty, message, Row, Space } from "antd";
import React, { useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import AppButton from "../../components/Button/Button";
import EyeIcon from "../../assets/open-eye-icon.svg";
import ShareIcon from "../../assets/share-icon.svg";
import RemoveWatchListIcon from "../../assets/remove-watchlist-icon.svg";
import "./AuctionDetail.css";
import { useDispatch, useSelector } from "react-redux";
import { placeBidAPI } from "../../apis/bids/BidAPI";
import { useState } from "react";
import { addToWatchListAPI } from "../../apis/watchlist/WatchlistAPI";
import InfoBid from "../../components/InfoBid/Index";

import {
  _addToWatchListAction,
  _removeFromWatchListAction,
} from "../../redux/actions/watchlist/WatchListActions";
import Loader from "../../components/Loader/Loader";
import SocialShare from "../../components/SocialShare/SocialShare";
import { constRoute, CREDENTIALS, URLS } from "../../utils/Constants";
import {
  errorMessage,
  logoutAndNavigateToHome,
  renderAuctionItemBGColorOnStatus,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
  successMessage,
} from "../../utils/common-functions";
import CommonConfirmationModal from "../../components/CommonComponents/common-Confirmation-modal";
import { getWalletApi } from "../../apis/deposit";
import AuctionImageViewer from "../../components/AuctionImages/AuctionImages";
import CommonTextInput from "../../components/Input/Input";
import { getSingleAuctionsAPI } from "../../apis/auctions/AuctionsAPI";
import { useTranslation } from "react-i18next";
import ClockIcon from "../../assets/clock-icon.svg";
import TimeRemainingTimer from "../../components/CommonComponents/RemainingTimeTimer";
import HighestBidderIcon from "../../assets/highest-bidder-icon.svg";
import CustomButton from "../../components/CommonComponents/custom-button/CustomButton";
import ProxyBid from "../../components/ProxyBid/Index";
import socket from "../../socket/index";
import moment from "moment";

const AuctionDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const companyData = useSelector((state) => state.companyData.companyData);

  let dataFromStore = useSelector((state) => {
    return state?.auctions?.auctionsData?.find(
      (auction) => auction?.id === Number(id)
    );
  });

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const [isProxy, setIsProxy] = useState(false);
  const watchlist = useSelector((state) => state.watchlist.data);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [showSocialShare, setSocialShare] = useState(false);
  const [visibleNotLoggedIn, setVisibleNotLoggedIn] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [visibleLessBalance, setVisibleLessBalance] = useState(false);
  const [visibleUserPending, setVisibleUserPending] = useState(false);
  const [visibleUserBlocked, setVisibleUserBlocked] = useState(false);

  const [showAuctionGallery, setShowAuctionGallery] = useState(false);
  const [data, setData] = useState(dataFromStore);
  const [_minBidAmount, setMinBidAmount] = useState("");
  const [bidAmount, setBidAmount] = useState("");

  const handleSocketEvent = useCallback(async () => {
    try {
      await getAuctionByIdFunc();
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    socket.on("auction", (data) => {
      handleSocketEvent(data);
    });
    return () => {
      socket.off("auction", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (id) {
      getAuctionByIdFunc(id);
    }
  }, [id]);

  useEffect(() => {
    // if (data) {
    //   setIsProxy(data?.bidStatuses?.bidLimit ? true : false);
    // }
    // else {
    //   navigate(constRoute?.myBids);
    // }
  }, [data]);

  // console.log("data", data);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getWalletApi();
      if (response?.data?.statusCode === 200) {
        setWalletData(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const getAuctionByIdFunc = async () => {
    setLoading(true);
    try {
      const response = await getSingleAuctionsAPI(id);
      if (response?.data?.statusCode === 200) {
        setData(response?.data?.data);
        const data = response.data.data;
        const _bidAmount = (
          data?.lastBidPrice === 0
            ? data?.minIncrement + data?.price / data?.totalUnits
            : data?.lastBidPrice / data?.totalUnits + data?.minIncrement
        ).toFixed(2);
        setMinBidAmount(_bidAmount);
        setBidAmount(_bidAmount);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const onPlaceBid = async (auctionId, isProxy) => {
    try {
      if (isNaN(bidAmount)) {
        errorMessage(t("bidAmountNumberError"));
        return;
      }
      if (bidAmount < _minBidAmount) {
        const errMessage = t("bidAmountNumberError");
        errorMessage(`${errMessage} $${_minBidAmount}`);
        return;
      }

      const result = await placeBidAPI(
        auctionId,
        bidAmount * data.totalUnits,
        isProxy,
        bidAmount * data.totalUnits
      );
      if (result?.data?.statusCode === 200) {
        successMessage(t("bidPlaced"));
        // navigate(constRoute.myBids);
      }
    } catch (e) {
      requestErrorHandel(e, navigate);
    }
  };

  const toggleIsProxy = (e) => {
    setIsProxy(e.target.checked);
  };

  const addToWatchList = (auctionId) => async () => {
    const authToken = localStorage.getItem(CREDENTIALS.AUTH_TOKEN);
    if (authToken?.length < 1 || !authToken) {
      messageApi.error(t("watchlist.loginToAddAuctionMessage"));
      logoutAndNavigateToHome();

      return;
    }
    try {
      setLoading(true);
      const result = await addToWatchListAPI(auctionId);
      setLoading(false);
      if (result.data.statusCode === 200) {
        if (data?.isWatchList) {
          dispatch(_removeFromWatchListAction(result.data.data));
          messageApi.success(t(`auctionRemovedWatchlist`));
        } else {
          dispatch(_addToWatchListAction(result.data.data));
          messageApi.success(t(`auctionAddedWatchlist`));
        }
        setData(result?.data?.data);
      }
    } catch (e) {
      setLoading(false);
      requestErrorHandel(e, navigate);
    }
  };

  const isAddedToWatchList = (auctionId) => {
    const watchlistIds = watchlist.map((el) => data?.id);
    return watchlistIds?.includes(auctionId);
  };

  const onShareAuction = () => setSocialShare(true);
  const openReport = (url) => () => window.open(url, "_blank");

  const openTerms = () => navigate(constRoute?.termsAndConditions);
  // navigate(`/auction-detail/${data.id}/terms&conditions`, {
  //   state: { data: data.termConditions },
  // });

  const openWhatsapp = () => {
    window.open(`https://wa.me/${companyData?.whatsApp?.value}`, "_blank");
  };

  const currentTime = moment().tz("America/Chicago");
  const endsAtTime = moment(data?.endAt);
  const timeDifference = endsAtTime.diff(currentTime, "milliseconds");
  const isAuctionExpired = timeDifference <= 0;
  return (
    <div className="common-layout min-container">
      {contextHolder}
      <ProtectedHeader />
      <Loader loading={loading} />
      <CommonConfirmationModal
        title={t(`popups.exclusiveAccessAwaits`)}
        heading=""
        para={t(`popups.bePartOf`)}
        visibleModal={visibleNotLoggedIn}
        handleCancel={() => setVisibleNotLoggedIn(false)}
        confimBtnTitle={t(`login.sign-in`)}
        onClickConfirm={() => navigate(constRoute?.login)}
        cancelBtnTitle={t(`signup.signup`)}
        onClickCancel={() => navigate(constRoute?.signup)}
      />

      <CommonConfirmationModal
        title={t(`popups.lowBalance`)}
        heading={t(`popups.balanceIsLess`)}
        para={t(`popups.balanceIsLess`)}
        visibleModal={visibleLessBalance}
        handleCancel={() => setVisibleLessBalance(false)}
        confimBtnTitle={t(`navMenu.deposit`)}
        onClickConfirm={() => navigate(constRoute?.deposit)}
        cancelBtnTitle={t(`popups.cancel`)}
        onClickCancel={() => setVisibleLessBalance(false)}
      />

      <CommonConfirmationModal
        title={user?.accountStatus?.toUpperCase()}
        heading={t(`userIsPending`)}
        para={t(`notApprovedByAdmin`)}
        visibleModal={visibleUserPending}
        handleCancel={() => setVisibleUserPending(false)}
        confimBtnTitle=""
        cancelBtnTitle={t(`continue`)}
        onClickCancel={() => setVisibleUserPending(false)}
      />

      <CommonConfirmationModal
        title=""
        heading={t(`userIsBlocked`)}
        para={t(`blockedUserInfo`)}
        visibleModal={visibleUserBlocked}
        handleCancel={() => setVisibleUserBlocked(false)}
        confimBtnTitle=""
        cancelBtnTitle=""
        onClickCancel={() => setVisibleUserBlocked(false)}
      />

      {data ? (
        <>
          <section className="auction-detail-section">
            <Row
              className="auction-detail-main"
              gutter={[20, 10]}
              justify={"space-between"}
            >
              <Col md={24} sm={24} xs={24}>
                <Row gutter={[0]} justify={"space-between"} align={"middle"}>
                  <div>
                    <h1>{renderItemDataOrEmptyNull(data?.name)}</h1>
                    <p className="t-slate">
                      {t(`auctionDetails.auctionID`)}: {data?.identifier}
                    </p>
                  </div>
                </Row>
                <Row gutter={30}>
                  <Col md={12} sm={24} xs={24}>
                    {data?.bidStatuses?.barStatus ? (
                      <div
                        className="highestBidder"
                        style={{
                          background: renderAuctionItemBGColorOnStatus(
                            data?.bidStatuses?.identifier
                          ),
                        }}
                      >
                        <img src={HighestBidderIcon} alt="Highest" />
                        <p>{data?.bidStatuses?.barStatus}</p>
                      </div>
                    ) : null}
                    <div
                      style={{
                        borderRadius: "0px 0px 10px 10px",
                        background: renderAuctionItemBGColorOnStatus(
                          data?.bidStatuses?.identifier
                        ),
                      }}
                      className="auction-details-first-col"
                    >
                      <Row
                        className="firstRowContainer"
                        style={{ padding: "28px" }}
                      >
                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="textRight medium__font t-slate">
                                {t(`auctionDetails.serialNumber`)}
                              </span>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="semiBold__font">
                                {renderItemDataOrEmptyNull(data?.serialNumber)}
                              </span>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="medium__font t-slate">
                                {t(`manufacturer`)}
                              </span>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="semiBold__font">
                                {renderItemDataOrEmptyNull(
                                  data?.manufacture?.name
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="textRight medium__font t-slate">
                                {t(`auctionDetails.Sku`)}
                              </span>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span
                                className="semiBold__font"
                                style={{
                                  wordBreak: "break-word",
                                }}
                              >
                                {renderItemDataOrEmptyNull(data?.sku)}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="textRight medium__font t-slate">
                                {t(`auctionDetails.grade`)}
                              </span>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="semiBold__font">
                                {renderItemDataOrEmptyNull(data?.grade)}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="medium__font t-slate">
                                {t(`quantity`)}
                              </span>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="semiBold__font">
                                {renderItemDataOrEmptyNull(data?.totalUnits)}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="medium__font t-slate">
                                {t(`auctionDetails.noOfBids`)}
                              </span>
                            </Col>
                            <Col lg={12} md={24} sm={24} xs={24}>
                              <span className="semiBold__font">
                                {renderItemDataOrEmptyNull(data?.totalBids)}
                              </span>
                            </Col>
                          </Row>
                        </Col>

                        <Col span={12}>
                          <Row className={"auctionPropertiesRow"}>
                            {data?.totalBids > 0 ? (
                              <>
                                <Col lg={12} md={24} sm={24} xs={24}>
                                  <span className="textRight medium__font t-slate">
                                    {t(`auctionDetails.lastBid`)}
                                  </span>
                                </Col>
                                <Col lg={12} md={24} sm={24} xs={24}>
                                  <span className="semiBold__font">
                                    $
                                    {renderItemDataOrEmptyNull(
                                      data?.lastBidPrice / data?.totalUnits,
                                      2
                                    )}
                                  </span>
                                </Col>
                              </>
                            ) : (
                              <>
                                {data?.price > 0 ? (
                                  <>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                      <span className="textRight medium__font t-slate">
                                        {t(`auctionDetails.unitPrice`)}
                                      </span>
                                    </Col>
                                    <Col lg={12} md={24} sm={24} xs={24}>
                                      <span className="semiBold__font">
                                        $
                                        {renderItemDataOrEmptyNull(
                                          data?.price / data?.totalUnits,
                                          2
                                        )}
                                        /Per Unit
                                      </span>
                                    </Col>
                                  </>
                                ) : null}
                              </>
                            )}
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row className="item" style={{ marginTop: "5px" }}>
                            <img
                              src={ClockIcon}
                              alt={"time"}
                              className={"auction-clock-icon-auction-details"}
                            />
                            <p className="semiBold__font">
                              <TimeRemainingTimer endAt={data?.endAt} />
                            </p>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {data?.bidStatuses?.identifier !== "B-0005" &&
                    data?.bidStatuses?.identifier !== "B-0002" &&
                    data?.bidStatuses?.identifier !== "B-0003" &&
                    !isAuctionExpired && (
                      <Col md={12} sm={24} xs={24}>
                        <div className="auction-details">
                          <Row
                            justify={"space-between"}
                            align={"middle"}
                            className="bidAmountPriceRow"
                          >
                            <p className="t-slate-light medium__font bid-amount-label">
                              {t(`auctionDetails.bidAmount`)}
                            </p>
                            <Row align={"middle"}>
                              {data?.lastBidPrice > 0 && (
                                <Col span={24}>
                                  <Row justify={"end"}>
                                    <Space>
                                      <p className="t-slate-light medium__font bid-amount-label">
                                        {t(`auctionDetails.lastBid`)}
                                      </p>
                                      <h7 className="bold__font">
                                        {" "}
                                        $
                                        {renderItemDataOrEmptyNull(
                                          data?.lastBidPrice / data?.totalUnits,
                                          2
                                        )}{" "}
                                      </h7>
                                    </Space>
                                  </Row>
                                </Col>
                              )}
                              {data?.bidStatuses?.bidLimit ? (
                                <Col span={24}>
                                  <Row justify={"end"}>
                                    <Space>
                                      <p
                                        style={{ margin: "0 " }}
                                        className="t-slate-light medium__font bid-amount-label"
                                      >
                                        {t(`auctionDetails.proxyBid`)}
                                      </p>
                                      <h7 className="bold__font">
                                        $
                                        {renderItemDataOrEmptyNull(
                                          data?.bidStatuses?.bidLimit /
                                            data?.totalUnits,
                                          2
                                        )}
                                      </h7>
                                    </Space>
                                  </Row>
                                </Col>
                              ) : null}
                            </Row>
                          </Row>
                          <Row justify={"space-between"}>
                            <div className="last-bid-price-div">
                              <CommonTextInput
                                placeholder={renderItemDataOrEmptyNull(
                                  _minBidAmount,
                                  2
                                )}
                                type="number"
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  console.log(">>>>>> value", value);
                                  if (
                                    typeof value !== "number" ||
                                    isNaN(value) ||
                                    !isFinite(value)
                                  ) {
                                    return;
                                  }
                                  setBidAmount(value);
                                }}
                              />
                            </div>
                            <Row style={{ gap: 5 }}>
                              <div
                                className="watch-list-btn"
                                onClick={addToWatchList(data.id)}
                              >
                                <img
                                  src={
                                    data.isWatchList
                                      ? RemoveWatchListIcon
                                      : EyeIcon
                                  }
                                  alt={"view"}
                                />
                              </div>

                              <div
                                className="watch-list-btn"
                                onClick={onShareAuction}
                              >
                                <img
                                  src={ShareIcon}
                                  width={"15rem"}
                                  alt={"share"}
                                />
                              </div>
                            </Row>
                            <Col span="24">
                              <CustomButton
                                title={t(`auctionDetails.bidNow`)}
                                className="bidNowButtonAtAuctionDetails"
                                onClick={async () => {
                                  if (!isLoggedIn) {
                                    setVisibleNotLoggedIn(true);
                                  } else if (
                                    user?.accountStatus === "pending"
                                  ) {
                                    setVisibleUserPending(true);
                                  } else if (
                                    user?.accountStatus === "blocked"
                                  ) {
                                    setVisibleUserBlocked(true);
                                  } else if (
                                    walletData?.currentBalance <
                                    bidAmount * data.totalUnits * 0.2
                                  ) {
                                    setVisibleLessBalance(true);
                                  } else {
                                    await onPlaceBid(data.id, isProxy);
                                  }
                                }}
                              ></CustomButton>
                            </Col>
                            <Col md={24}>
                              <Row>
                                <Space direction="horizontal">
                                  <p className="medium__font t-slate">
                                    {t(`auctionDetails.minBidAmount`)}: $
                                    {renderItemDataOrEmptyNull(
                                      _minBidAmount,
                                      2
                                    )}
                                  </p>
                                  <p className="t-slate-light">
                                    ({t(`auctionDetails.priceMinIncrement`)})
                                  </p>
                                </Space>
                              </Row>
                            </Col>
                            <Col span="24" className="custom-col">
                              <Row>
                                <Space direction="horizontal">
                                  <p className="medium__font t-slate">
                                    {t(`auctionDetails.totalBidAmount`)}: $
                                    {renderItemDataOrEmptyNull(
                                      (bidAmount !== 0
                                        ? bidAmount
                                        : _minBidAmount) * data?.totalUnits,
                                      2
                                    )}
                                  </p>
                                </Space>
                              </Row>
                            </Col>
                            <div className="bid-proxy">
                              <Checkbox
                                className="bold__font"
                                // disabled={!isLoggedIn}
                                checked={isProxy}
                                onChange={toggleIsProxy}
                              >
                                {t(`auctionDetails.enableProxyBid`)}
                              </Checkbox>
                              <ProxyBid />
                            </div>
                          </Row>
                          {/* 
                        "" - No bid placed
                        B-0001 - highest bidder
                        B-0002 - won and pending payment
                        B-0003 - won and payment done
                      */}
                          {["", "B-0001"].includes(
                            data?.bidStatuses?.identifier
                          ) && (
                            <>
                              <Col
                                md={24}
                                sm={24}
                                xs={24}
                                style={{ paddingLeft: 0, paddingTop: 20 }}
                              >
                                <InfoBid />
                              </Col>
                            </>
                          )}
                        </div>
                      </Col>
                    )}
                </Row>
              </Col>
            </Row>
            <Row className="footerButtons" justify="center">
              <Col md={8} xs={24} className="chatNowBtn">
                <AppButton
                  type={"default"}
                  style={{
                    color: "red",
                    fontFamily: "Manrope-Bold",
                    width: "100%",
                    background: "#fff",
                  }}
                  onClick={openWhatsapp}
                >
                  {t(`auctionDetails.chatNow`)}
                </AppButton>
              </Col>
              <Col md={8} xs={24} className="termsAndConditionsBtn">
                <AppButton
                  type={"default"}
                  style={{
                    color: "red",
                    fontFamily: "Manrope-Bold",
                    width: "100%",
                    background: "#fff",
                  }}
                  onClick={openTerms}
                >
                  {t(`auctionDetails.termsConditions`)}
                </AppButton>
              </Col>
            </Row>
          </section>

          <SocialShare
            visible={showSocialShare}
            toggleVisibile={setSocialShare}
            url={`${process.env.REACT_APP_WEBSITE_URL}/auction-detail/${data.id}`}
          />
        </>
      ) : (
        <Empty
          description={<p className="medium__font">{t("noDataFound")}</p>}
        />
      )}
      {showAuctionGallery && data.files.length > 0 && (
        <AuctionImageViewer
          images={data.files}
          show={showAuctionGallery}
          toggleShow={setShowAuctionGallery}
        />
      )}
    </div>
  );
};

export default AuctionDetail;
